import React from "react";
import { Box, Select, FormField } from "grommet";

export interface IListSelectorProps {
  value: string;
  setValue: React.Dispatch<React.SetStateAction<string>>;
}

export const ListSelector: React.FC<IListSelectorProps> = ({
  value,
  setValue,
}) => {
  const options = [
    {
      label: "Dinners",
      value: "dinners",
    },
    {
      label: "List",
      value: "list",
    },
  ];
  return (
    <Box fill align="center" justify="start" margin={{ bottom: "30px" }}>
      <FormField
        fill
        name="select"
        htmlFor="select"
        label="List Select"
        required
      >
        <Select
          id="select"
          name="select"
          placeholder="Select"
          labelKey="label"
          valueKey={{ key: "value", reduce: true }}
          value={value}
          options={options}
          onChange={({ value: nextValue }) => setValue(nextValue)}
        />
      </FormField>
    </Box>
  );
};
