import React, { useContext } from "react";
import { Box, Heading } from "grommet";

import { SelectedListContext } from "../../providers";
import { useChooser } from "../../api/Chooser";
import { ChooserList } from "../ChooserList";

export interface IChooserManagerProps {
  pickList: string;
  amount: number | "all";
  requests: number;
  shouldRefetch: boolean;
}

export const ChooserManager: React.FC<IChooserManagerProps> = ({
  pickList,
  amount,
  requests,
  shouldRefetch,
}) => {
  const { list } = useContext(SelectedListContext);
  const { data, refetch } = useChooser(pickList, amount, requests);

  if (shouldRefetch) {
    refetch();
  }

  return (
    <Box>
      {data ? (
        <>
          <Heading alignSelf="center" level="2">
            Random Options
          </Heading>
          <ChooserList data={data} />
        </>
      ) : null}
      {list.length > 0 ? (
        <>
          <Heading alignSelf="center" level="2">
            Chosen Options
          </Heading>
          <ChooserList isResultsList={false} data={list} />
        </>
      ) : null}
    </Box>
  );
};
