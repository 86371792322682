import React, { useContext } from "react";
import { Button } from "grommet";
import { PlusCircle } from "react-feather";

import { IListItemResponse } from "../../types";
import { SelectedListContext } from "../../providers";

export interface IAddItemProps {
  listItem: IListItemResponse;
}

const addItem = (
  item: IListItemResponse,
  list: IListItemResponse[]
): IListItemResponse[] => {
  if (!list.find((checkItem) => checkItem.id === item.id)) {
    return [...list, item];
  } else {
    return list;
  }
};

export const AddItem: React.FC<IAddItemProps> = ({ listItem }) => {
  const { list, setList } = useContext(SelectedListContext);

  return (
    <Button
      aria-label="add"
      onClick={() => {
        setList(addItem(listItem, list));
      }}
    >
      <PlusCircle />
    </Button>
  );
};
