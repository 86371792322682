import React from "react";
import { Box, List, Text } from "grommet";

import { IListItemResponse } from "../../types";
import { AddItem } from "../AddItem";
import { RemoveItem } from "../RemoveItem";

export interface IChooserListProps {
  data: IListItemResponse[];
  isResultsList?: boolean;
}

export const ChooserList: React.FC<IChooserListProps> = ({
  data,
  isResultsList = true,
}) => (
  <Box fill height="100%" align="center">
    <List style={{ width: "100%" }} data={data}>
      {(datum: IListItemResponse) => (
        <Box direction="row-responsive" gap="medium" align="center">
          {isResultsList ? <AddItem listItem={datum} /> : null}
          {!isResultsList ? <RemoveItem listItem={datum} /> : null}
          <Text weight="bold">
            {!isResultsList && `(${datum.meta.weight}) `}
            {datum.text}
          </Text>
        </Box>
      )}
    </List>
  </Box>
);
