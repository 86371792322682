import React, { useContext } from "react";
import { Button } from "grommet";
import { Trash2 } from "react-feather";

import { IListItemResponse } from "../../types";
import { SelectedListContext } from "../../providers";

const removeItem = (
  { id }: IListItemResponse,
  list: IListItemResponse[]
): IListItemResponse[] => list.filter((checkItem) => checkItem.id !== id);

export interface IRemoveItemProps {
  listItem: IListItemResponse;
}

export const RemoveItem: React.FC<IRemoveItemProps> = ({ listItem }) => {
  const { list, setList } = useContext(SelectedListContext);

  return (
    <Button
      aria-label="add"
      onClick={() => {
        setList(removeItem(listItem, list));
      }}
    >
      <Trash2 />
    </Button>
  );
};
