import axios from 'axios';
import { useQuery } from 'react-query';

import { IListItemResponse } from '../../types';

const backendUrl = process.env.REACT_APP_BACKEND_URL || 'http://localhost:3001';

export const useChooser = (pickList: string, amount: number | "all", requests: number) => {
  return useQuery(
    ['chooser', pickList, amount, requests],
    () => fetchChooser(pickList, amount),
    {
      retry: false,
      cacheTime: 6000,
      enabled: requests > 0,
      refetchOnWindowFocus: false,
    }
  );
}

const fetchChooser = async (list: string, amount: number | "all") => {
  return axios.get<IListItemResponse[]>(`${backendUrl}/lists/${list}/${amount}`)
    .then(res => {return res.data});
}
