import React, { createContext, useState, useMemo } from "react";

import { IListItemResponse } from "../types";

export interface ISelectedListContextProps {
  list: IListItemResponse[];
  setList: React.Dispatch<React.SetStateAction<IListItemResponse[]>>;
}

export const SelectedListContext = createContext<ISelectedListContextProps>({
  list: [],
  setList: () => {},
});

export const SelectedListProvider: React.FC = ({ children }) => {
  const [list, setList] = useState<IListItemResponse[]>([]);
  const value = useMemo(() => ({ list, setList }), [list]);
  return (
    <SelectedListContext.Provider value={value}>
      {children}
    </SelectedListContext.Provider>
  );
};
