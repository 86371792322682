import React from "react";
import { QueryClientProvider, QueryClient } from "react-query";
import { Grommet, Box } from "grommet";

import { SelectedListProvider } from "./providers";
import { ChooserForm } from "./components/ChooserForm";

const queryClient = new QueryClient();

export const theme = {
  global: {
    font: {
      family: "Roboto",
      size: "14px",
      height: "20px",
    },
  },
};

const App = () => {
  return (
    <QueryClientProvider client={queryClient}>
      <SelectedListProvider>
        <Grommet theme={theme}>
          <Box
            pad="medium"
            style={{
              maxWidth: "600px",
              marginLeft: "auto",
              marginRight: "auto",
            }}
          >
            <ChooserForm />
          </Box>
        </Grommet>
      </SelectedListProvider>
    </QueryClientProvider>
  );
};

export default App;
