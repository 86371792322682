import React from "react";
import { Box, RangeInput, FormField } from "grommet";

export interface IAmountSliderProps {
  defaultValue: number;
  // setValue: Dispatch<SetStateAction<number>>;
  setValue: React.Dispatch<React.SetStateAction<number>>;
}

export const AmountSlider: React.FC<IAmountSliderProps> = ({
  defaultValue,
  setValue,
}) => {
  return (
    <Box fill align="center" justify="start" margin={{ bottom: "30px" }}>
      <FormField fill name="select" htmlFor="amount" label="Select A Number">
        <RangeInput
          defaultValue={defaultValue}
          step={1}
          min={1}
          max={10}
          onChange={(e) => {
            console.log(e.target.value);
            setValue(parseInt(e.target.value));
          }}
          id="amount-slider"
          name="amount"
        />
      </FormField>
    </Box>
  );
};
