import React, { useState } from "react";
import { Form, Box, Button, Heading } from "grommet";

import { AmountSlider } from "../AmountSlider";
import { ListSelector } from "../ListSelector";
import { ChooserManager } from "../ChooserManager";

export const ChooserForm = () => {
  const [pickList, setPickList] = useState<string>("dinners");
  const [amount, setAmount] = useState<number>(2);
  const [shouldGetAll, setShouldGetAll] = useState<boolean>(false);
  const [requestReady, setRequestReady] = useState<number>(0);

  return (
    <>
      <Box>
        <Heading alignSelf="center" level="1">
          Chooser
        </Heading>
        <Form
          onSubmit={(e) => {
            e.preventDefault();
            setShouldGetAll(false);
            setRequestReady(requestReady + 1);
          }}
        >
          <ListSelector value={pickList} setValue={setPickList} />
          <AmountSlider defaultValue={amount} setValue={setAmount} />
          <Box
            direction="row"
            justify="around"
            align="center"
            margin={{ bottom: "30px" }}
          >
            <Button primary label="Get Random Items" type="submit" />
            <Button
              primary
              label="Get All"
              onClick={() => {
                setShouldGetAll(true);
                setRequestReady(requestReady + 1);
              }}
            />
          </Box>
        </Form>
      </Box>
      <ChooserManager
        amount={shouldGetAll ? "all" : amount}
        pickList={pickList}
        requests={requestReady}
        shouldRefetch={false}
      />
    </>
  );
};
